export const RESULT = {
  SUCCESS: "SUCCESS",
  FAIL_SESSION: "FAIL_SESSION",
};

export const COMMON_CODE = {
  WORK: "100",
  INQ: "200",
  DEVELOP: "300",
};
